<template>
    <div>
        <div v-if="questions">
            <!--Quiz-->
            <div class="item" v-if="typeCat.value !== 'all' && questionIndex <= questions.length">
                <div v-for="(question, index) in questions" :key="index">
                    <div v-show="index === questionIndex" class='item'>
                        <h2 class="item-title">{{ question.title }}:</h2>

                        <!--Механизмы трансформации-->
                        <div class="item__open-modal" v-if="question.id === 'mechanism'">
                            <a class="item__open-modal-link" href="#" @click.prevent="showMechanisms = true">
                                <img src="/img/icon-mechanizm.png" alt="шестерни">
                                <p>Подробнее</p>
                            </a>
                        </div>

                        <ol class="item-lists">
                            <li class="item-list"
                                v-for="response in question.responses"
                                :key="response.value"
                                :class="{ 'active': isChecked(question.answer, {value: response.value, price: response.price || 0 }) }"
                            >
                                <label>
                                    <input
                                            :type="question.type"
                                            :value="{ value: response.value, price: response.price || 0 }"
                                            :data-catagery="response.category || category"
                                            :data-subcategory="response.subcategory || subCategory"
                                            :data-imgCategory="response.imgCategory || ''"
                                            :name="question.id"
                                            v-model="question.answer"
                                            @input="update"
                                    >
                                    <span>{{ response.value }}</span>
                                </label>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>


            <!--Таблица с результатом-->
            <div class="quiz__result" v-if="typeCat.value !== 'all' && questionIndex === questions.length">
                <div class="quiz__result-header">
                    <a class="quiz__result-header-open_modal" href="#" @click.prevent="showGallery = true">
                        <img src="/img/icon-armchair.png" alt="кресло">
                        <p>Примеры работ</p>
                    </a>
                    <div class="quiz__result-header-share" v-if="isLead">
                        <share-social :leadId="id" :leadTitle="lead.title"/>
                    </div>
                </div>


                <div class="quiz__result-comments">
                    <input class="input input-title" type="text" placeholder="Название сделки" v-model="comments.title">
                    <input class="input input-title" type="text" placeholder="Адрес" v-model="comments.address">
                    <textarea class="input input-descr" placeholder="Описание сделки" v-model="comments.descr"></textarea>
                </div>

                <table class="quiz__result-table" >
                    <tr :class="index" v-for='(list, index) in data.items' :key="index">
                        <td v-for='(item, key) in list' :key="key">
                            <template v-if="index === 'questions'">
                                <a href="#" title='Перейти в данный этап' @click.prevent='returnStep(item.position)'>{{ item.val }}</a>
                            </template>

                            <span v-else v-text="index === 'prices' ? item + ' руб.' : item + ''"></span>
                        </td>
                    </tr>
                </table>
            </div>


            <!--Навигация-->
            <div class="quiz-btns" v-if="typeCat.value !== 'all'">
                <button class="quiz__btn quiz__btn-prev" @click="prev">
                    назад
                </button>
                <button v-if="!isLastQuestion" class="quiz__btn quiz__btn-next" :disabled="btnLocked"  @click="next">
                    далее
                </button>

                <button v-if="isLastQuestion" class="quiz__btn quiz__btn-save" :disabled="!btnSaveLocked" @click="saveLead">
                    сохранить
                </button>
            </div>

            <works
                v-if="showGallery"
                :typeMebel="data.imgCategory"
                @hide-gallery="showGallery = false"
            />

            <Mechanizms
                v-if="showMechanisms"
                @hide-mechanisms="showMechanisms = false"
            />
        </div>


        <div v-else>
            <!--Выбор категории-->
            <div class="item" v-show="typeCat.value === 'all'">
                <h2 class="item-title">Тип мебели:</h2>
                <ol class="item-lists">
                    <li class="item-list" v-for="type in typesFurniture" :key="type.category">
                        <label>
                            <input type="radio"
                                   :value="{ value: type.category, name: type.value }"
                                   name="type_furniture"
                                   v-model="typeCat"
                            >
                            <span>{{type.value}}</span>
                        </label>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
    import Works from "../components/Works";
    import Mechanizms from "../components/Mechanizms";
    import ShareSocial from "../components/ShareSocial";

    export default {
        props: ['id'],
        components: {Works, Mechanizms, ShareSocial},
        data() {
            return {
                comments: {
                    title: '',
                    descr: '',
                    address: ''
                },
                typesFurniture: [
                    {value: 'диван', category: 'sofa'},
                    {value: 'кресло', category: 'armchair'},
                    {value: 'стулья', category: 'chair'},
                    {value: 'кровать', category: 'bed'},
                ],
                typeCat: {value: 'all', name: ''},
                imgCategory: '',
                category: '',
                subCategory: '',
                questionIndex: 0,
                isActive: false,
                data: null,
                showGallery: false,
                showMechanisms: false
            }
        },



        beforeMount () {
            if (this.$props.id) {
                this.$store.dispatch('leads/loadOneLead', this.$props.id)
            }

            this.$store.dispatch('findings/loadFindings')
        },

        computed: {
            questions() {
                return this.$store.getters['findings/findingsCategory'](this.typeCat.value)
            },

            lead() {
                return this.$store.getters['leads/oneLead']
            },

            btnLocked() {
                const count = this.questions[this.questionIndex];
                return count && count.answer.length === 0 || !count;
            },

            btnSaveLocked() {
                return this.comments.title.trim().length > 3
            },

            isLastQuestion() {
                return this.questions.length === this.questionIndex
            },

            isLead() {
                return this.$props.id && this.lead
            }
        },


        methods: {
            next() {
                const questionsLength = this.questions.length;

                if (this.category || this.subCategory) {
                    let arr = [];
                    const item = this.questions;

                    if (this.questionIndex === questionsLength - 1) {
                        this.questionIndex++
                        this.getData()
                    }

                    for (let i = 0; i < item.length; i++) {
                        if( (typeof item[i].category === "string"
                            && item[i].category.includes(this.category)) || !item[i].category) {
                            arr.push(i)
                        } else {
                            item[i].answer = [];
                        }
                    }

                    arr.find(el => {
                        if (el > this.questionIndex) return this.questionIndex = el;
                    });
                }

                else if (this.questionIndex < questionsLength && this.questionIndex !== questionsLength - 1) {
                    this.questionIndex++
                }

                else {
                    this.questionIndex++
                    this.getData();
                }
            },

            getData() {
                this.data = {
                    items: {
                        questions: [],
                        answers: [],
                        prices: []
                    }
                };

                const dataItems = this.data.items

                dataItems.questions.push({val: 'Тип мебели', position: 'cat'})
                dataItems.answers.push(this.typeCat.name)
                dataItems.prices.push(0)



                this.questions.forEach((question, index) => {
                    let answer = question.answer;

                    if (question.answer.value && question.answer.value !== '') {
                        answer = answer.value;
                        let price = question.answer.price;

                        dataItems.answers.push(answer)
                        dataItems.prices.push(price)
                        dataItems.questions.push({val: question.title, position: index})
                    }

                    if (Array.isArray(answer) && answer.length > 0) {
                        const answersArr = [];
                        const pricesArr = [];

                        answer.forEach(item => {
                            answersArr.push(item.value)
                            pricesArr.push(item.price)
                        })

                        dataItems.answers.push(answersArr)
                        dataItems.prices.push(pricesArr)
                        dataItems.questions.push({val: question.title, position: index})
                    }
                });

                if (this.imgCategory.length === 0) this.imgCategory = this.typeCat.value
                this.data.imgCategory = this.imgCategory
            },

            prev() {
                if (this.questionIndex === 0) {
                    this.typeCat.value = 'all';
                    this.category = '';
                    this.subcategory= '';
                    return;
                }

                if (this.category || this.subCategory) {
                    const arr = [];
                    const item = this.questions;

                    for (let i = 0; i < item.length; i++) {
                        if((typeof item[i].category === "string" && item[i].category.includes(this.category)) || !item[i].category) {
                            arr.push(i)
                        }
                    }

                    arr.reverse();
                    arr.find(el => {
                        if (el < this.questionIndex) return this.questionIndex = el;
                    });

                } else {
                    this.questionIndex--
                }
            },

            update(e) {
                const el = e.target;

                const category = el.getAttribute('data-catagery');
                const subCategory = el.getAttribute('data-subcategory');
                const imgCategory = el.getAttribute('data-imgCategory');

                if (category !== '' && category !== undefined) {
                    this.category = category;
                }

                if (subCategory !== '' && subCategory !== undefined) {
                    this.subCategory = subCategory;
                }

                if (imgCategory !== '' && imgCategory !== undefined) {
                    this.imgCategory = this.typeCat.value + '_' + imgCategory;
                }
            },

            isChecked(a, b) {
                const value1 =  JSON.stringify(a);
                const value2 = JSON.stringify(b);

                return value1.includes(value2);
            },

            returnStep(position) {
                if (position === 'cat') {
                    this.typeCat.value = 'all'
                    this.category = ''
                    this.subcategory= ''
                    this.data = ''
                    this.imgCategory = ''
                    this.questionIndex = 0
                } else {
                    const questionsArr = this.questions;

                    questionsArr.forEach((item, index) => {
                        if (index > position) {
                            item.answer = []
                        }
                    })

                    this.questionIndex = position
                }
            },

            saveLead() {
                const dateNow = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`

                this.data.date = this.isLead ? this.lead.date : dateNow
                this.data.date_update = this.isLead ? dateNow : null
                this.data.id = this.isLead ? this.lead.id : null
                this.data.title = this.comments.title
                this.data.descr = this.comments.descr
                this.data.address = this.comments.address

                this.data.type_mebel = this.typeCat.value
                this.data.category = this.category
                this.data.subCategory = this.subCategory
                this.data.questionIndex = this.questionIndex

                if (this.isLead) {
                    this.$store.dispatch('leads/updateLead', this.data)
                } else {
                    this.$store.dispatch('leads/createLead', this.data)
                }
            },
        },

        watch: {
            lead() {
                setTimeout(() => {
                    this.typeCat.value = this.lead.type_mebel
                    this.typeCat.name = this.lead.items.answers[0]
                    this.category = this.lead.category
                    this.subCategory = this.lead.subCategory
                    this.imgCategory = this.lead.imgCategory
                    this.questionIndex = this.lead.questionIndex

                    this.comments.title = this.lead.title
                    this.comments.descr = this.lead.descr
                    this.comments.address = this.lead.address

                    const questionsCategory = this.questions
                    const leadItems = this.lead.items

                    for (let i = 0; i < questionsCategory.length; i++) {
                        const title = questionsCategory[i].title

                        for (let j = 0; j < leadItems.questions.length; j++) {
                            if (title === leadItems.questions[j].val) {
                                if (Array.isArray(leadItems.answers[j])) {
                                    for (let k = 0; k < leadItems.answers[j].length; k ++) {
                                        questionsCategory[i].answer.push({ value: leadItems.answers[j][k], price: leadItems.prices[j][k] })
                                    }
                                }
                                else {
                                    questionsCategory[i].answer.push({ value: leadItems.answers[j], price: leadItems.prices[j] })
                                }
                            }
                        }
                    }

                    this.getData()
                }, 500)
            }
        }
    }
</script>

<style lang="scss">
    @import '../style/colors.scss';

    .item {
        animation-name: ffadeInUp;
        -webkit-animation-duration: .4s;
        animation-duration: .4s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        &-title {
            font-weight: 900;
            font-size: 52px;
            margin-bottom: 45px;
        }
        &-lists {
            counter-reset: myCounter;
            display: inline-block;
            margin: 0;
            padding: 0;
            list-style-type: none;
            min-width: 500px;
        }
        &-list {
            position: relative;
            overflow: hidden;
            background: #fff;
            border: 1px solid #999;
            margin-bottom: 18px;
            &>label {
                display: block;
                padding: 15px 17px;
                font-weight: 400;
                line-height: 1.24;
                user-select: none;
                cursor: default;
                & input {
                    display: none;
                }
                & span {
                    position: relative;
                    font-size: 24px;
                    font-weight: 400;
                    padding-left: 28px;
                    &:before {
                        counter-increment: myCounter;
                        content: counter(myCounter);
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #999;
                    }
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            &.active {
                background: #f1f1f1;
                border: 1px solid #181818;
            }
        }
        &__open-modal {
            margin-bottom: 30px;
            &-link {
                display: inline-flex;
                align-items: center;
                color: #5e5e5e;
                font-weight: 400;
                font-size: 22px;
                transition: all ease .3s;
                &:hover {
                    opacity: .8;
                }
                &>img {
                    margin-right: 15px;
                }
            }
        }
    }

    .quiz {
        &-btns {
            margin-top: 35px;
            user-select: none;
        }
        &__btn {
            display: inline-block;
            outline: none;
            transition: all ease .3s;
            border-width: 0;
            user-select: none;
            font-size: 26px;
            font-weight: 900;
            cursor: pointer;
            background: transparent;
            padding: 0;
            &:disabled {
                pointer-events: none;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                opacity: .8;
            }
            &-prev {
                margin-right: 30px;
                padding: 15px 0;
            }
            &-next {
                background: #181818;
                color: #fff;
                padding: 15px 35px;
                &:disabled {
                    background: #8b8b8b;
                }
            }
            &-save {
                color: $success;
                &:disabled {
                    opacity: .6;
                }
            }
        }
        &__result {
            &-table {
                text-align: center;
                box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
                border-collapse: collapse;
                border: 2px solid white;
                & .questions {
                    & td {
                        background: #f8f8f8;
                        font-size: 24px;
                        font-weight: 500;
                        padding: 10px 20px;
                        border: 1px solid #999;
                        transition: .2s;
                        &:hover {
                            opacity: .6;
                        }
                    }
                }
                & td {
                    font-size: 20px;
                    font-weight: 400;
                    padding: 10px;
                    border: 1px solid #999;
                }
            }
            &-comments {
                box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
                padding: 30px 20px;
                margin-bottom: 40px;
                &>.input {
                    padding-bottom: 8px;
                    border-bottom: 1px solid #949494;
                    height: 32px;
                }
                &>.input-title {
                    margin-bottom: 20px;
                }
            }
            &-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;
                &-open_modal {
                    display: inline-flex;
                    align-items: center;
                    color: #5e5e5e;
                    font-weight: 400;
                    font-size: 22px;
                    transition: all ease .3s;
                    &:hover {
                        opacity: .8;
                    }
                    &>img {
                        margin-right: 15px;
                    }
                }
            }
        }
    }


    @keyframes ffadeInUp {
        0% {
            opacity: 0;
            transform: translate3d(0, 12px, 0);
        }

        100% {
            opacity: 1;
            transform: none;
        }
    }


    @keyframes showModal {
        0% {
            opacity: 0;
            transform: translateY(50%);
        }

        100% {
            opacity: 1;
            transform: none;
        }
    }
</style>
