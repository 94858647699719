<template>
    <div>
        <div class="works">
            <a class="works__btn-close" href="#" @click.prevent="$emit('hide-gallery')"></a>
            <div class="container">
                <h2 class="section-title works-title">Наши работы</h2>
                <div class="works__block">
                    <light-gallery
                            :images="images"
                            :index="index"
                            :disable-scroll="true"
                            @close="index = null"
                    ></light-gallery>

                    <div class="works-imgs">
                        <a
                                href="#"
                                v-for="(thumb, thumbIndex) in img[typeMebel]"
                                :key="thumbIndex"
                                class="works-img"
                                @click.prevent="index = thumbIndex"
                        >
                            <img :src="'/img/' + typeMebel + '/' + thumb + '.jpg'" alt="наши работы">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { LightGallery } from 'vue-light-gallery';

    export default {
        components:{
            LightGallery
        },
        props: ['typeMebel'],
        data() {
            return {
                data: {
                    imgCategory: 'bed'
                },
                img: {
                    sofa_straight: 79,
                    sofa_corner: 48,
                    sofa_kitchen: 38,
                    sofa_modular: 2,
                    armchair: 34,
                    chair: 33,
                    bed: 6,
                },
                index: null
            }
        },
        computed: {
            typeMebelVal() {
                return this.typeMebel
            },

            images() {
                let imagesArr = [];

                for (let i = 1; i < this.img[this.typeMebelVal] + 1; i++) {
                    imagesArr.push({url: '/img/' + this.typeMebelVal + '/' + i + '.jpg'})
                }
                return imagesArr
            }
        }
    }
</script>

<style lang="scss">
    .works {
        position: fixed;
        overflow: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #fff;
        padding: 5% 10%;
        z-index: 10;
        animation: .3s showModal;
        &-title {
            margin-bottom: 45px;
        }
        &__btn-close {
            position: fixed;
            top: 50px;
            right: 50px;
            width: 70px;
            height: 70px;
            transition: all ease .3s;
            &:hover {
                opacity: .5;
            }
            &:before, &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                background: #000;
            }
            &:before {
                width: 72px;
                height: 3px;
            }
            &:after {
                width: 3px;
                height: 72px;
            }
        }
        &-imgs {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            margin: -15px;
        }
        &-img {
            &>img {
                width: 300px;
                height: 200px;
                object-fit: cover;
                margin: 15px;
            }
        }
    }
</style>
