<template>
    <div>
        <div class="mechanisms">
            <a class="mechanisms__btn-close" href="#" @click.prevent="$emit('hide-mechanisms')"></a>
            <div class="container">
                <h2 class="section-title mechanisms-title">Механизмы трансформации</h2>
                <div class="mechanisms__block">
                    <div class="mechanisms__items">
                        <div class="mechanisms__item" v-for="(video, index) in items" :key="index">
                            <h4>{{video.title}}</h4>
                            <video
                                    preload="none"
                                    :poster="'img/' + video.value + '.jpg'"
                                    @click="play_video($event)"
                                    :key="index"
                                    data-play="pause"
                            >
                                <source
                                        :src="'video/' + video.value + '.mp4'"
                                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                                />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                items: [
                    {title: 'дельфин', value: 'dolphin'},
                    {title: 'еврокнижка', value: 'evroknijzka'},
                    {title: 'книжка', value: 'knijzka'},
                    {title: 'relax', value: 'relaks'},
                    {title: 'седафлекс', value: 'sedafleks'},
                    {title: 'тик-так', value: 'tik-tak'},
                    {title: 'вперед раскладной', value: 'vpered_rasklodnoy'},
                    {title: 'клик-кляк', value: 'klik-klyak'},
                ]
            }
        },
        methods: {
            play_video(e) {
                const el = e.target;
                const thisPlay = el.getAttribute('data-play');

                if (thisPlay === 'pause') {
                    el.play();
                    el.setAttribute('data-play', 'play');
                } else {
                    el.pause();
                    el.setAttribute('data-play', 'pause');
                }
            },
        }
    }
</script>

<style lang="scss">
    .mechanisms {
        position: fixed;
        overflow: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #fff;
        padding: 5% 10%;
        z-index: 10;
        animation: .3s showModal;
        &-title {
            margin-bottom: 45px;
        }
        &__btn-close {
            position: fixed;
            top: 50px;
            right: 50px;
            width: 70px;
            height: 70px;
            transition: all ease .3s;
            &:hover {
                opacity: .5;
            }
            &:before, &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                background: #000;
            }
            &:before {
                width: 72px;
                height: 3px;
            }
            &:after {
                width: 3px;
                height: 72px;
            }
        }
        &__items {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            margin: -15px -30px;
        }
        &__item {
            margin: 15px 30px;
            user-select: none;
            &>h4 {
                font-size: 30px;
                font-weight: 500;
                margin-bottom: 15px;
            }
            &>video {
                width: 300px;
                height: 200px;
                border: 1px solid #999;
                cursor: pointer;
            }
        }
    }
</style>
