<template>
    <div>
        <div class="share">
            <div class="share-links-wrapper" v-if="openShareLinks">
                <div class="share-links">
                    <div class="share-link"
                         v-for="link in shareLinks"
                         :key="link.icon"
                         :title="link.title"
                         @click="shareSocialOpen(link.url)"
                    >
                        <img :src="'/img/icons/' + link.icon + '.svg'" :alt="link.title">
                    </div>
                    <div class="share-link"
                         title="Скопировать ссылку"
                         @click="copyPageUrl"
                    >
                        <img src="/img/icons/copy.svg" alt="копия">
                    </div>
                </div>
            </div>
            <div class="share-btn" title="Поделиться" @click="openShareLinks = !openShareLinks">
                <div v-if="!openShareLinks">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.67 40" width="36.67" height="40">
                        <path d="M35.42 6.67A5.42 5.42 0 1130 1.25a5.42 5.42 0 015.42 5.42z"></path>
                        <path d="M30 13.33a6.67 6.67 0 116.67-6.66A6.67 6.67 0 0130 13.33zM30 2.5a4.17 4.17 0 104.17 4.17A4.17 4.17 0 0030 2.5zM35.42 33.33A5.42 5.42 0 1130 27.92a5.42 5.42 0 015.42 5.41z"></path>
                        <path d="M30 40a6.67 6.67 0 116.67-6.67A6.67 6.67 0 0130 40zm0-10.83a4.17 4.17 0 104.17 4.16A4.17 4.17 0 0030 29.17zM12.08 20a5.42 5.42 0 11-5.41-5.42A5.42 5.42 0 0112.08 20z"></path>
                        <path d="M6.67 26.67A6.67 6.67 0 1113.33 20a6.67 6.67 0 01-6.66 6.67zm0-10.84A4.17 4.17 0 1010.83 20a4.17 4.17 0 00-4.16-4.17z"></path>
                        <path d="M10.6 19.2a1.67 1.67 0 01-.82-3.12l15.46-8.81a1.67 1.67 0 011.65 2.9L11.42 19a1.63 1.63 0 01-.82.2zM26.07 33a1.64 1.64 0 01-.83-.22L9.78 23.92A1.67 1.67 0 1111.43 21l15.46 8.81a1.67 1.67 0 01.62 2.28 1.65 1.65 0 01-1.44.91z"></path>
                    </svg>
                </div>

                <div v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" width="37" height="37">
                        <g fill="#f44336">
                            <path d="M2.4 37a2.38 2.38 0 01-1.7-.7 2.41 2.41 0 010-3.39L32.91.7a2.4 2.4 0 113.39 3.39L4.09 36.3a2.38 2.38 0 01-1.69.7z"></path>
                            <path d="M34.6 37a2.36 2.36 0 01-1.69-.7L.7 4.09A2.4 2.4 0 014.09.7L36.3 32.91a2.41 2.41 0 010 3.39 2.38 2.38 0 01-1.7.7z"></path>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['leadId', 'leadTitle'],
        data() {
            return {
                openShareLinks: false,
            }
        },
        computed: {
            urlLink() {
                return `${window.location.origin}/lead/${this.leadId}`
            },
            encodeUrl() {
                return encodeURIComponent(this.urlLink)
            },
            shareLinks() {
                return [
                    {title: 'Facebook', icon: 'fb', url: `https://www.facebook.com/sharer.php?u=${this.encodeUrl}&title=${this.encodeStr(this.leadTitle)}`},
                    {title: 'VK', icon: 'vk', url: `https://vkontakte.ru/share.php?url=${this.encodeUrl}&title=${this.encodeStr(this.leadTitle)}`},
                    {title: 'Telegram', icon: 'tg', url: `https://telegram.me/share/url?url=${this.encodeUrl}&text=${this.encodeStr(this.leadTitle)}`},
                    {title: 'Viber', icon: 'viber', url: `viber://forward?text=${this.encodeStr(this.leadTitle + ' ')}${this.encodeUrl}`},
                    {title: 'WhatsApp', icon: 'whatshapp', url: `https://api.whatsapp.com/send?text=${this.encodeStr(this.leadTitle + ' ')}${this.encodeUrl}`},
                ]
            },
        },
        methods: {
            encodeStr(string) {
                return encodeURIComponent(string)
            },
            shareSocialOpen(url) {
                window.open(url);
                this.openShareLinks = false
            },
            async copyPageUrl() {
                try {
                    await navigator.clipboard.writeText(this.urlLink);
                    this.$toast.success("Ссылка скопирована")
                } catch (err) {
                    console.error('Ошибка копирования в буфер: ', err);
                }
            }
        }
    }
</script>

<style lang="scss">
    .share {
        display: flex;
        align-items: center;
        user-select: none;
        &-btn {
            cursor: pointer;
            transition: .2s;
            &:hover {
                opacity: .6;
            }
        }
        &-links {
            display: flex;
            align-items: center;
            &-wrapper {
                animation: fadeIn ease .5s
            }
        }
        &-link {
            margin-right: 10px;
            transition: .2s;
            cursor: pointer;
            &:last-of-type {
                margin-right: 20px;
            }
            &:hover {
                opacity: .6;
            }
            &>img {
                width: 40px;
                height: 40px;
            }
        }
    }
</style>
